import React from "react"
import { Link } from "gatsby"
import "./buttons.css"

function ArrowButton({ id, link, text, classes }) {
  return (
    <Link to={link} id={id} className={classes}>
      {text}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-6 w-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
        />
      </svg>
    </Link>
  )
}

export default ArrowButton
